<div class="d-flex flex-wrap justify-content-between">
  <div>
    @if (edit && !add) {
      <button class="btn btn-outline-medium mt-2 mt-lg-0" type="button" routerLink="/{{ rootLink }}/view/{{ itemLink }}{{ endLink }}">
        <i class="fa-light fa-undo-alt me-0 me-md-2"></i>
        <span class="d-none d-md-inline">{{ 'global.Annuler' | translate }}</span>
      </button>
    }
    @if ((!edit || add) && !hideBackButton) {
      <button class="btn btn-primary mt-2 mt-lg-0" type="button" [routerLink]="backLink === undefined ? ['/' + rootLink] : backLink">
        <i class="fa-light fa-chevron-left me-0 me-md-2"></i>
        <span class="d-none d-md-inline">{{ 'global.Retour' | translate }}</span>
      </button>
    }
    <ng-content select="[leftButtons]"></ng-content>
  </div>
  <div class="d-flex align-items-center">
    <ng-content select="[rightButtons]"></ng-content>
    @if (!add && !edit) {
      @if (duplicate.observed) {
        <button class="btn btn-primary mt-2 mt-lg-0 me-2" type="button" (click)="duplicate.emit()">
          <i class="fa-light fa-copy me-0 me-md-2"></i>
          <span class="d-none d-md-inline">
            {{ 'global.Dupliquer' | translate }}
          </span>
        </button>
      }
      @if (allowEdit()) {
        <button class="btn btn-primary mt-2 mt-lg-0" type="button" routerLink="/{{ rootLink }}/edit/{{ itemLink }}{{ endLink }}">
          <i class="fa-light fa-pen me-0 me-md-2"></i>
          <span class="d-none d-md-inline">{{ 'global.Modifier' | translate }}</span>
        </button>
      }
    }
  </div>
</div>
